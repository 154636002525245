*,*::before,*::after {
  box-sizing: border-box;
}
ul[class],ol[class] {
  padding: 0;
}
body,h1,h2,h3,h4,p,li,figure,figcaption,blockquote,dl,dd {
  margin: 0;
}
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}
ul[class],ol[class] {
  list-style: none;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: #b10047;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}
img {
  max-width: 100%;
  display: block;
}
section > * + *, article > * + * {
  margin-top: 1em;
}
input,button,textarea,select {
  font: inherit;
}
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

body{
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  background-image: url(../img/bg.png);
  color: #2e2e2e;
  font-size: 16px;
}
.o-container{
  padding-left: 10px;
  padding-right: 10px;
}
.o-text-center{
  text-align: center;
}
.o-section{
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 15px;
}
h1{
  font-size: 26px;
}
h2, h3{
  border-left: 5px solid #ff0066;
  padding: 5px;
  color: #333;
}
.o-box--left{
  float: left;
  margin-right: 10px;
}
.o-box--right{
  float: right;
  margin-left: 10px;
}
.o-item::after{
  content: '';
  display: block;
  clear: both;
}
.o-item{
  p + p{
    margin-top: 12px;
  }
}
.o-table-wrap{
  overflow-x: auto;
}
.o-btn{
  display: inline-block;
  font-weight: 700;
  color: #2e2e2e;
  background-color: #fed0d0;
  text-decoration: none;
  padding: 8px 10px;
  border-radius: 21px;
  min-width: 110px;
  text-align: center;
  &:hover{
    background-color: darken(#fed0d0, 5%);
  }
}
@media(min-width: 768px){
  .o-container{
    max-width: 768px;
    margin: 0 auto;
  }
}
@media(min-width: 992px){
  .o-container{
    max-width: 1000px;
    margin: 0 auto;
  }
}