.c-main-img{
  max-width: 360px;
  display: block;
  margin: 0 auto;
  img{
    height: auto;
  }
}
.c-info{
  display: block;
  margin-left: -10px;
  margin-right: -10px;
  img{
    width: 100%;
    height: auto;
  }
}
.c-main{
  background: #fff5e8;
  padding-bottom: 15px;
  p:not([class]){
		font-size: 17px;
	}
	ul:not([class]),
	ol:not([class]){
		padding-left: 20px;
		margin-bottom: 20px;
		@media(min-width: 767px){
			padding-left: 40px;
		}
	}
	ul:not([class]) > li,
	ol:not([class]) > li{
		padding: 2px 0 2px 10px;
		margin-bottom: 10px;
	}
	table:not([class]){
		border-collapse: separate;
		border-spacing: 0;
		width: 100%;
		td{
			padding: 8px;
			text-align: left;
			border: 1px solid #fed0d0;
			min-width: 100px;
		}
	}
}
@media(min-width: 768px){
  .c-main{
    display: block;
    &__inner{
      display: flex;
    }
  }
  .c-content{
    flex: 1;
  }
}