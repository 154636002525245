.c-header{
  &__inner{
    margin-left: -10px;
    margin-right: -10px;
    background: #fff5e8;
  }
}
.c-logo{
  display: flex;
  justify-content: center;
  img{
    width: 200px;
    height: auto;
  }
}
.c-menu-toggle {
  span{
    display: block;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #fed0d0;
    text-align: center;
    font-weight: 700;
    color: #2e2e2e;
    font-size: 20px;
    cursor: pointer;
  }
}
.c-menu {
  display: none;
  &.is-active{
    display: block;
  }
  &__list[class] {
    margin: 0;
  }
  &__item {
    border-top: 1px solid #1a1414;
  }
  &__link {
    display: block;
    text-decoration: none;
    color: #2e2e2e;
    text-align: center;
    padding: 5px 15px;
    background-color: #fed0d0;
    &:hover{
      background-color: lighten(#fea3a2, 5%);
    }
  }
}
@media(min-width: 768px){
  .c-header{
    position: relative;
    margin-bottom: 35px;
    &::after{
      content: '';
      position: absolute;
      width: 100%;
      height: 34px;
      background-color: #fed0d0;
      bottom: -34px;
    }
    &__inner{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .c-menu-toggle span{
    display: none;
  }
  .c-menu{
    display: block;
    &__item{
      display: inline-block;
      border-top: 0;
      margin-left: 15px;
    }
  }
}
@media(min-width: 992px){
  .c-logo img{
    width: 300px;
  }
}