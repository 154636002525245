.c-aside {
  &__box {
    background-color: #fed0d0;
    padding: 10px 15px;
    border-radius: 5px;
  }
  &__box + &__box{
    margin-top: 15px;
  }
  &__title {
    padding: 5px 0;
    display: block;
    font-weight: 700;
    font-size: 18px;
  }
  &__content {
    border-radius: 5px;
    padding: 10px 15px;
    background-color: #fff;
  }
  &__date {
    display: block;
    font-weight: 600;
    border-bottom: 1px solid #0000005c;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  &__list[class] {
    list-style-type: circle;
    padding-left: 25px;
  }
  &__list--content[class]{
    list-style-type: decimal;
  }
  &__item {
    a{
      font-size: 14px;
    }
  }
  &__item + &__item{
    margin-top: 5px;
  }
 
}

@media(min-width: 768px){
  .c-aside{
    width: 40%;
    margin-left: 20px;

  }
}