.c-footer{
  background-color: #fed0d0;
  text-align: center;
  padding: 25px;
  padding-bottom: 25px;
  font-size: 14px;
  &__content{
    p + p{
      margin-top: 10px;
    }
  }
}